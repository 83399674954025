import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { ApiExpenseDocument } from 'amazon-textract-response-parser/dist/types/api-models'

import { createSelectors } from '@store/utils'

type Store = {
  file: File | null
  documentData: ApiExpenseDocument | null
  isAnalyzing: boolean
  setFile: (file: File | null) => void
  clearFile: () => void
  setDocumentData: (data: ApiExpenseDocument) => void
  setIsAnalyzing: (isAnalyzing: boolean) => void
  reset: VoidFunction
}

const initialState = { file: null, documentData: null, isAnalyzing: false }

export const createOCRStore = immer<Store>((set) => ({
  ...initialState,
  setFile: (file) => set({ file }),
  clearFile: () => set({ file: null }),
  setDocumentData: (documentData) => set({ documentData }),
  setIsAnalyzing: (isAnalyzing) => set({ isAnalyzing }),
  reset: () => set(initialState),
}))

const name = 'ocrStore'
const useOCRStoreBase = create(devtools(subscribeWithSelector(createOCRStore), { name, store: name }))

export const useOCRStore = createSelectors(useOCRStoreBase)
export default useOCRStore
